<template>
   <div class="m-box index-hot-box">
   <!--start ads:612-->
      <div class="public_hot_test">
        <div class="public_ht_title">热门推荐</div>
        <div class="public_ht_ul J_hotUl">
          <div class="swiper-container J_hotSwiper">
            <div class="swiper-wrapper">
                <a href="/?ac=jinnian" class="swiper-slide"><img src="@/assets/imgs/common/5f2d202f80e3b.png" alt="蛇年运程" /><p>蛇年运程</p></a>
                <a href="/?ac=bazijp" class="swiper-slide"><img src="@/assets/imgs/common/bazijp.jpg" alt="八字精批" /> <p>八字精批</p></a>
                <a href="/?ac=hehun" class="swiper-slide"><img src="@/assets/imgs/common/5f2d20301b3bd.png" alt="八字合婚" /> <p>八字合婚</p></a>
                <a href="/?ac=yinyuancs" class="swiper-slide"><img src="@/assets/imgs/common/5f2d2035cf683.png" alt="姻缘分析" /><p>姻缘分析</p></a>
                <a href="/?ac=bazi" class="swiper-slide"><img src="@/assets/imgs/common/5f2d2030047b4.png" alt="八字财运" /><p>八字财运</p></a>
                <a href="/?ac=jinnian" class="swiper-slide"><img src="https://szwh.oss-cn-chengdu.aliyuncs.com/assets/imgs/img/01.png?x-oss-process=style/webp" alt="2025运程" /><p>2025运程</p></a>
                <a href="/?ac=yt_bazixmjq" class="swiper-slide"><img src="@/assets/imgs/common/5f2d203548761.png" alt="姓名详批" /><p>姓名详批</p></a>
                <a href="/?ac=xmpd" class="swiper-slide"><img src="@/assets/imgs/common/5f2d2034f19de.png" alt="姓名配对" /><p>姓名配对</p></a>
                <a href="/?ac=hehun" class="swiper-slide"><img src="@/assets/imgs/common/5f2d2032de970.png" alt="恋爱配对" /><p>恋爱配对</p></a>
                <a href="/?ac=hehun" class="swiper-slide"><img src="@/assets/imgs/common/5f2d203244c40.png" alt="婚缘走势" /><p>婚姻走势</p></a>
                <a href="/?ac=ffqm" class="swiper-slide"><img src="@/assets/imgs/common/5f2d20307a299.png" alt="宝宝取名" /><p>宝宝取名</p></a>
                <a href="/?ac=ziwei" class="swiper-slide"><img src="@/assets/imgs/common/ziwei.jpg" alt="紫微斗数" /><p>紫微斗数</p></a>
            </div>
            <div class="hot_pages swiper-pagination"></div>
          </div>
        </div>
      </div>
      <!--ads:612 end-->   
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>